import {ActionEvent, Controller} from "@hotwired/stimulus";
import {TaggedLogger} from "@nextlot/core/utilities";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";
import {Tooltip as BsTooltip} from "bootstrap";

const _logger = TaggedLogger.get('FilterToggleWebcastSoldBidderTypeController');

export default class FilterToggleWebcastSoldBidderTypeController extends Controller {
    static targets = [ 'dropFilterWebcastSoldBidderType' ];

    declare readonly hasDropFilterWebcastSoldBidderTypeTarget: boolean;
    declare readonly dropFilterWebcastSoldBidderTypeTarget: HTMLSelectElement;


    connect() {
        _logger.debug('.connect()');
        if (this.hasDropFilterWebcastSoldBidderTypeTarget) {
          this.toggleTooltip(this.dropFilterWebcastSoldBidderTypeTarget);
        }
    }

    disconnect() {
        _logger.debug('.disconnect()');
    }


    onChangeRoundStatus(event:ActionEvent) {
        if (event.target instanceof HTMLSelectElement) {
          _logger.debug('>>> Webcast round status selected:', `${event.target.options[event.target.selectedIndex].text} (${event.target.value})` || 'Any')

          if (this.hasDropFilterWebcastSoldBidderTypeTarget) {
            const roundStatusValue: number = Number(event.target.value);
            const isEnabled: boolean = NextLotJSDATA.filter_webcast_round_type_ids?.some(rt => rt === roundStatusValue) || false;

            this.dropFilterWebcastSoldBidderTypeTarget.disabled = !isEnabled;
            this.dropFilterWebcastSoldBidderTypeTarget.selectedIndex = 0; // make sure is reset when changing the round type

            this.toggleTooltip(this.dropFilterWebcastSoldBidderTypeTarget);
          }
            }
    }

    toggleTooltip(elem:HTMLSelectElement) {
      if (elem) {
        const tooltip = BsTooltip.getInstance(this.dropFilterWebcastSoldBidderTypeTarget);
        elem.disabled ? tooltip.enable() : tooltip.disable(); // disable tooltip only when the dropFilterWebcastSoldBidderTypeTarget is enabled
      }
    }
}
