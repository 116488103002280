import React from "react";
import ReactDOM from "react-dom/client";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {TaggedLogger} from "@nextlot/core/utilities";
import NextLotJSDATA from "@nextlot/core/NextLotJSDATA";

const _logger = TaggedLogger.get('PowerSelect');

const styles = {
    container: (provided) => ({
        ... provided,
        flex: 1,
    }),
};

const theme = (provided) => ({
    ... provided,
    spacing: {
        ... provided.spacing,
        controlHeight: 36
    }
});

enum PowerSelectTypesEnum {
    LinkedLotsGroup = 'linked_lots_group',
    AuctionRing = 'auction_ring'
}

type PowerSelectConfigProps = {
    options: Array<any>,
    placeholder: string
}

export default {
    renderIfNeeded,
    theme,
    styles,
}


function renderIfNeeded() {
    document.querySelectorAll('select[data-use-power-select="true"]').forEach((node:HTMLSelectElement) => {
        renderPowerSelect(node);
    });

    const powerSelectConfigData = {}

    const getPowerSelectConfig = (powerSelectType: string): PowerSelectConfigProps => {
        if (powerSelectConfigData[powerSelectType]) {
            return powerSelectConfigData[powerSelectType]
        }

        switch (powerSelectType) {
            case PowerSelectTypesEnum.LinkedLotsGroup:
                powerSelectConfigData[powerSelectType] = {
                    options: NextLotJSDATA.data_linked_lots_groups && NextLotJSDATA.data_linked_lots_groups
                        .map((r) => ({label: r.name, value: r.id, extraInfo: r.extra_info})),
                    placeholder: 'start typing to search for existing links or to create a new one'
                }
                break;
            case PowerSelectTypesEnum.AuctionRing:
                powerSelectConfigData[powerSelectType] = {
                    options: NextLotJSDATA.data_auction_rings && NextLotJSDATA.data_auction_rings
                        .map((r) => ({label: r.name, value: r.id, extraInfo: r.extra_info})),
                    placeholder: 'start typing to search for existing rings or to create a new one'
                }
                break;
            default:
                throw 'invalid power select type'
        }

        return powerSelectConfigData[powerSelectType];
    };


    document.querySelectorAll('input[type="hidden"][data-acts-as-power-select]').forEach((inputHiddenEl: HTMLElement) => {
        renderActsAsPowerSelect(inputHiddenEl as HTMLInputElement, getPowerSelectConfig(inputHiddenEl.dataset.actsAsPowerSelect));
    });
}







function renderPowerSelect(selectHtmlEl: string|HTMLSelectElement) {
    const selectEl:HTMLSelectElement = getSelectElement(selectHtmlEl);

    // selectEl.dataset.fancySelect

    const htmlOptions = extractOptionsFromHtmlElement(selectEl);

    const reactSelectEl = document.createElement('div');

    selectEl.after(reactSelectEl)

    const onChangeFunc = (value:{label:string, value:any}, extra:{action:string}) => {
        _logger.debug('#.onChange:', value, extra);

        if (extra.action === 'select-option') {
            selectEl.value = value.value;
        }
        else if (extra.action === 'clear') {
            selectEl.value = null;
        }

        // Dispatch the `change` event when programmatically change the <select /> HTML elem
        // Needed when attaching and listening the `change` event on a <select /> HTML elem
        selectEl.dispatchEvent(new Event('change'));
      }

    // rails uses an `insert_blank` to have a default value as placeholder
    // remove all options that don't have a value
    const validOptions = htmlOptions.filter(o => o.value !== '');

    const defaultValue = validOptions.filter(v => v.isSelected);

    const placeholder = (htmlOptions.find(v => v.value === '') || {}).label || 'Select ...';

    ReactDOM.createRoot(reactSelectEl).render(
        <Select
            className={'power-select__container'}
            classNamePrefix={'power-select'}
            isMulti={!!selectEl.multiple}
            options={validOptions}
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={onChangeFunc}

            escapeClearsValue
            isClearable

            styles={styles}
            theme={theme}
        />);

    // inspired by select2-hidden-accessible class
    selectEl.style.position = 'absolute';
    selectEl.style.height = '1px';
    selectEl.style.width = '1px';
    selectEl.style.border = '0';
    selectEl.style.padding = '0';
    selectEl.style.overflow = 'hidden';
    selectEl.style.whiteSpace = 'nowrap';
    selectEl.style.clip = 'rect(0 0 0 0)';
    selectEl.style.clipPath = 'inset(50%)';
}






function extractOptionsFromHtmlElement(selectEl:HTMLSelectElement):Array<any> {

    const optionsCollection = selectEl.options;

    const options = [];
    for (let i = 0; i < optionsCollection.length; i++) {
        const optionEl = optionsCollection.item(i);
        options.push({
            label: optionEl.text,
            value: optionEl.value,
            isSelected: !!optionEl.selected,
            isDisabled: !!optionEl.disabled,
        });
    }

    return options;
}




function getSelectElement(selectHtmlEl: string|HTMLSelectElement):HTMLSelectElement {
    let selectEl;

    if (typeof selectHtmlEl === 'string') {
        selectEl = document.getElementById(selectHtmlEl);
        if (! selectEl) {
            throw 'invalid argument `selectHtmlEl`: html id not found';
        }
    }
    else {
        selectEl = selectHtmlEl;
        if (! selectEl) {
            throw 'invalid argument `selectHtmlEl`: cannot be falsey';
        }
    }
    return selectEl;
}







function renderActsAsPowerSelect(inputHiddenEl: HTMLInputElement, powerSelectConfigProps: PowerSelectConfigProps) {
    // _logger.debug('#renderActsAsPowerSelect: ', inputHiddenEl);

    const existingInputHiddenElValue = inputHiddenEl.value || '';
    const existingInputHiddenElValueParts = existingInputHiddenElValue.split('__id:');

    let defaultValue = null;
    let defaultInputValue = '';
    if (existingInputHiddenElValueParts.length === 2) {
        defaultValue = powerSelectConfigProps.options.find((sr) => (sr.value === parseInt(existingInputHiddenElValueParts[1])));
        // _logger.debug('#renderAuctionRingsPowerSelect >> defaultValue: ', defaultValue);
    }
    else if (existingInputHiddenElValue) {
        defaultInputValue = existingInputHiddenElValue;
    }

    const getOptionLabelFunc = (option): string => {
        if (option.extraInfo) {
            return `${option.label}  (${option.extraInfo})`
        }
        return option.label;
    };

    const onChangeFunc = (value:{label:string, value:any}, extra:{action:string}) => {
        // _logger.debug('#renderAuctionRingsPowerSelect>.onChange:', value, extra);

        switch(extra.action) {
            case 'select-option': {
                inputHiddenEl.value = '__id:' + value.value;
                return;
            }
            case 'create-option': {
                inputHiddenEl.value = value.value;
                return;
            }
            case 'clear': {
                inputHiddenEl.value = null;
                return;
            }
        }
    };


    const reactSelectEl = document.createElement('div');
    inputHiddenEl.after(reactSelectEl);

    ReactDOM.createRoot(reactSelectEl).render(
        <CreatableSelect
            defaultValue={defaultValue}
            defaultInputValue={defaultInputValue}
            options={powerSelectConfigProps.options}
            getOptionLabel={getOptionLabelFunc}

            createOptionPosition={'first'}

            placeholder={powerSelectConfigProps.placeholder}
            escapeClearsValue={true}
            isClearable={true}

            onChange={onChangeFunc}

            styles={styles}
            theme={theme}
        />
    );
}
