
import {runOnDOMContentLoaded} from "@nextlot/core/utilities";

import PowerSelect from "./forms/helpers/PowerSelect";
import HtmlEditor from './forms/helpers/HtmlEditor';
import CodeEditor from "./forms/helpers/CodeEditor";

import ItemMediaFilesUpload from './forms/helpers/ItemMediaFilesUpload';
import AuctionThumbImageUpload from './forms/helpers/AuctionThumbImageUpload';
import SiteThumbImageUpload from "./forms/helpers/SiteThumbImageUpload";
import SiteLogoImageUpload from "./forms/helpers/SiteLogoImageUpload";
import LogoBannerImageUpload from "./forms/helpers/LogoBannerImageUpload";

import AuctionForm from './forms/auction_form';
import LotForm from './forms/lot_form';
import BidIncrementsTableForm from './forms/bid_increments_table_form';
import BuyerPremiumsForm from './forms/buyer_premiums_form';

import auctionsWebcastManageReloadPageIfNeeded from './pages/auctions_webcast_manage';

import renderDromoInventoryItemsBulkImportIfNeeded from './pages/site_inventory_items_list_page';
import renderDromoLotsBulkImportIfNeeded from "./pages/auctions_lot_list_page";
import ItemMediaFilesBulkImportUpload from "./forms/helpers/ItemMediaFilesBulkImportUpload";
import BidderInvoicingAuctionsForm from './forms/bidder_invoicing_auctions_form';


runOnDOMContentLoaded(function () {

    (window as any).Pagy?.init();

    AuctionForm.renderIfNeeded('form_auction');
    AuctionForm.renderIfNeeded('form_auction_settings_bidder_approval');
    LotForm.renderIfNeeded();
    BidIncrementsTableForm.renderIfNeeded();
    BuyerPremiumsForm.renderIfNeeded();

    PowerSelect.renderIfNeeded();
    HtmlEditor.renderIfNeeded();
    CodeEditor.renderIfNeeded();

    ItemMediaFilesUpload.renderIfNeeded();
    AuctionThumbImageUpload.renderIfNeeded();
    SiteThumbImageUpload.renderIfNeeded();
    SiteLogoImageUpload.renderIfNeeded();
    LogoBannerImageUpload.renderIfNeeded();
    ItemMediaFilesBulkImportUpload.renderIfNeeded();

    BidderInvoicingAuctionsForm.renderIfNeeded();



    document.querySelectorAll('[data-clipboard-copy-value]').forEach(function (el:HTMLElement) {
        el.addEventListener('click', async (evt) => {
            el.style.backgroundColor = 'yellow';
            await navigator.clipboard.writeText(el.dataset.clipboardCopyValue as string);
            setTimeout(() => {
                el.style.backgroundColor = '';
            }, 500);

        });
    });


    renderDromoInventoryItemsBulkImportIfNeeded();
    renderDromoLotsBulkImportIfNeeded();
    auctionsWebcastManageReloadPageIfNeeded();
});







